import * as qs from 'querystring'
import $ from 'jquery'
import moment from 'moment'
import Cookies from 'js-cookie'

import { getTourPriceNum } from '@/helpers/price'

moment.suppressDeprecationWarnings = true
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/i
const phoneRegex = /^\+?[0-9]{10,15}$/ // Allows optional '+' and 10-15 digits

/**
 * Only for itlay - transform city to region
 * @param {String} city
 * @return {String}
 */
export const getRegionFromCity = (city) => {
  let output
  const input = city.trim()
  if (input === 'Rome') {
    output = 'Lazio'
  } else if (input === 'Florence') {
    output = 'Toscana'
  } else if (input === 'Venice') {
    output = 'Veneto'
  } else if (input === 'Milan') {
    output = 'Lombardia'
  } else if (input === 'Pompeii') {
    output = 'Campania'
  } else {
    output = input
  }
  return output
}
/**
 * count guest per type
 * @param {Object} guests
 * @return {Object}
 */
export const getGuestByType = (guests) => {
  const formated = {
    adults: 0,
    infants: 0,
    children: 0,
    students: 0
  }
  guests.forEach((el) => {
    formated[el.type] = el.count
  })
  return formated
}
/**
 * remove the last / of path if exist
 * and path not equal to '/'
 * @param {String} path url to be trimmed
 * @return {String}
 */
export const removeTrailingSlash = (path) => {
  const end = path.length - 1
  const lastChar = path.charAt(end)
  return lastChar === '/' && path.length > 1 ? path.slice(0, end) : path
}

/**
 * Captialize String
 * e.g 'rome tour' => 'Rome Tour'
 * @param {String} text
 * @return {String}
 */
export const capitalize = (text) => {
  const arr = text.split(' ')
  return arr.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  }).join(' ').trim()
}

/**
 * remove dash from str
 * e.g. 'rome-tour' => 'rome tour'
 * @param {String} text - e.g 'rome-tour'
 * @return {Sring}
 */
export const stripTours = (text) => {
  const str = text.replace(/(tours|tour)/g, '').replace(/-/g, ' ')
  return capitalize(str)
}

/**
 * convert time to float
 * @param {String} time e.g 3hrs 15
 * @return {Number}
 */
export const timeStringToFloat = (time) => {
  if (!time) {
    return ''
  }
  const hoursMinutes = time.split(/[.:]/)
  const hours = parseInt(hoursMinutes[0], 10)
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0
  return hours + minutes / 60
}

export const select2Keyboard = () => {
  $('.select2,.select2-dropdown').on('keydown', function (e) {
    const KEYS = { UP: 38, DOWN: 40 }
    let $sel2 = $(this).closest('.select2')
    if ($sel2.length === 0) {
      $sel2 = $('.select2.select2-container--open')
    }

    const $sel = $sel2.data('element')
    if ($sel.length) {
      let newValue

      if (e.keyCode === KEYS.DOWN && !e.altKey) {
        const selected = $sel.find('option:selected')
        if (selected.length) {
          newValue = selected.nextAll(':enabled').first().val()
        } else {
          newValue = $sel.find('option:first').nextAll(':enabled').first().val()
        }
        e.stopPropagation()
        e.preventDefault()
      } else if (e.keyCode === KEYS.UP) {
        newValue = $sel.find('option:selected').prevAll(':enabled').first().val()
        e.stopPropagation()
        e.preventDefault()
      }

      if (newValue !== undefined) {
        $sel.val(newValue)
        $sel.trigger('change')
      }
    }
  })
}

export const getPageCategory = (pageName) => {
  switch (pageName) {
    case 'AboutUs':
    case 'CareerPage':
    case 'GuideWithUs':
    case 'ContactPage': return 'CompanyPage'
    case 'PrivacyPolicy':
    case 'TermsOfUsePage':
    case 'TermsPage':
    case 'HealthSafety':
    case 'CancellationPolicy': return 'SupportPage'
    case 'Affiliate':
    case 'TravelAgents': return 'PartnerPage'
    case 'BookingManage': return 'MyBooking'
    case 'PaymentPage': return 'ShoppingCart'
    case 'TourDetailPage': return 'TourPage'
    case 'CityPageSub': return 'CityPage'
    default: return pageName
  }
}

export const includesPage = (pagesToShow, pageName) => {
  if (!pagesToShow || !pageName) {
    return false
  }
  if (pagesToShow.includes('All pages on site') || pagesToShow.includes('Whole Site')) {
    return true
  }
  const matchName = pageName.toLowerCase()
  if (pagesToShow.includes('Whole Site (except Homepage)')) {
    return matchName !== 'homepage'
  }
  const formattedPages = pagesToShow.map(page => page.replaceAll(' ', '').toLowerCase())
  const category = getPageCategory(pageName).toLowerCase()
  const res = formattedPages.includes(matchName) || formattedPages.includes(`${matchName}s`) ||
  formattedPages.includes(category) || formattedPages.includes(`${category}s`)
  // console.log('processedPageNames => ', formattedPages)
  // console.log('processed pageName => ', matchName, category)
  // console.log('can show => ', res)
  return res
}

/**
 * if routePatch matches one of the limitedPages
 * ex: /new-york-tours/xxx matches [new-york-tours]
 * @param {string[]} limitedPages
 * @param {string} routePath
 * @returns boolean
 */
export const matchPages = (limitedPages, routePath) => {
  if (!limitedPages || !limitedPages.length === 0) {
    return true
  }
  for (const limitedPage of limitedPages) {
    const m = routePath.includes(limitedPage)
    if (m) {
      return true
    }
  }
  return false
}

export const localeFormatYearless = (dateStr) => {
  const formatL = moment.localeData().longDateFormat('ll')
  const formatYearlessL = formatL.replace(/Y/g, '').replace(/^\W|\W$|\W\W/, '')
  return moment(dateStr).format(formatYearlessL)
}

export const getTodayStr = (format) => {
  const today = new Date()
  const todayStr = moment(today).format(format)
  return todayStr
}

export const addDays = (date, days) => {
  return moment(date).add(days, 'day').format('YYYY/MM/DD')
}

export const formatLink = (url, route) => {
  const query = route?.query
  // console.log('route => ', route)
  let res = url
  if (!url.endsWith('/')) {
    res = url + '/'
  }
  let qryStr = ''
  if (query) {
    qryStr = qs.stringify(query)
    if (qryStr) {
      res = res + `?${qryStr}`
    }
  }
  return res
}

export const scrollToTop = () => {
  $('html, body').animate({ scrollTop: 0 }, 400)
}

export const scrollElementTo = (elName, toPlace, speed) => {
  $(elName).animate({ scrollTop: toPlace }, speed ?? 100)
}

export const getDateStr = (cur, diff, format) => {
  return moment(cur).add(diff, 'days').format(format)
}

export const validateEmail = (email) => {
  return email.match(emailRegex)
}

export const validatePhoneNumber = (phoneNumber) => {
  return phoneRegex.test(phoneNumber)
}

export const getOneTrustSettings = () => {
  let otEnabled = true
  const otCookieConsent = (Cookies.get('OptanonConsent') || '').split('&').filter(Boolean)
  for (let i = 0; i < otCookieConsent.length; i++) {
    const eachOt = otCookieConsent[i].split('=')
    if (eachOt[0] === 'groups') {
      // console.log('ot 0 => ', eachOt)
      const groupArr = decodeURIComponent(eachOt[1]).split(',')
      for (let j = 0; j < groupArr.length; j++) {
        const eachOtInGroup = groupArr[j].split(':')
        if (eachOtInGroup[0] === 'C0002' && eachOtInGroup[1] === '1') {
          otEnabled = false
        }
      }
    }
  }
  // console.log('ot enabled => ', otEnabled)
  return otEnabled
}

export const showTourPromoBanner = (tourPromo, location) => {
  if (tourPromo?.show && tourPromo?.promoLocation?.includes(location)) {
    return true
  } else {
    return false
  }
}

export function formatCityName (cityUrl) {
  if (!cityUrl) {
    return ''
  }

  if (cityUrl.toLowerCase() === 'uk') {
    return 'UK'
  }

  if (cityUrl.toLowerCase() === 'usa') {
    return 'USA'
  }

  if (cityUrl.toLowerCase() === 'united-states') {
    return 'USA'
  }

  const res = cityUrl
    .split('-') // Split the string at the dashes
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' ') // Join them with a space

  return res
}

export const formatDateTime = (datetimeStr, fmtStr) => {
  return moment(datetimeStr).format(fmtStr)
}

export const sortTours = (tours, sorter) => {
  const sorterName = sorter || 'popularity'
  switch (sorterName) {
    case 'pricelowtohigh':
      tours.sort(
        (a, b) => {
          const priceA = getTourPriceNum(a.tour?.priceMap, 'USD')
          const priceB = getTourPriceNum(b.tour?.priceMap, 'USD')
          return Number(priceA) - Number(priceB)
        }
      )
      return tours
    case 'pricehightolow':
      tours.sort(
        (a, b) => {
          const priceA = getTourPriceNum(a.tour?.priceMap, 'USD')
          const priceB = getTourPriceNum(b.tour?.priceMap, 'USD')
          // console.log('tour :', a.tour.bookingTypeId, 'priceA :>> ', priceA)
          return Number(priceB) - Number(priceA)
        }
      )
      return tours
    case 'rating':
      tours.sort((a, b) => {
        const aRate = a.review.stars || 0
        const bRate = b.review.stars || 0
        return bRate - aRate
      })
      return tours
    case 'popularity':
    default:
      break
  }
}

export function isWalksTour (tour) {
  return tour.brandCard?.data?.name === 'Walks Brand Card'
}

export function sortWalksAndUnavailableToLast (tours, displayAllWalksTours, walksToursDefaultDisplay) {
  let newAddedIdx = -1
  const unavailTours = []
  const privateTours = []
  const walksTours = []
  for (const tour of tours) {
    if (tour.isPrivate) {
      privateTours.push(tour)
    } else if (isWalksTour(tour)) {
      walksTours.push(tour)
    } else if (tour.tour?.state === 'Currently Unavailable') {
      unavailTours.push(tour)
    }
  }
  // reorder tours: devour normal -> devour private -> walks -> unavailable last
  const newTourList = tours.filter(
    tour => tour.tour?.state !== 'Currently Unavailable' && !tour.isPrivate && !isWalksTour(tour)
  )

  // sort private tours at the last of devour tours
  newTourList.push(...privateTours)
  // sort walks tours after devour tours
  // - need to sort walksTours -> unavailable last
  const walksUnavailable = []
  for (const walksTour of walksTours) {
    if (walksTour.tour?.state === 'Currently Unavailable') {
      walksUnavailable.push(walksTour)
    }
  }
  const newWalksTourList = walksTours.filter(
    tour => tour.tour?.state !== 'Currently Unavailable'
  )
  newWalksTourList.push(...walksUnavailable)
  // need to display all tours for "View All" and dynamic search results
  if (!displayAllWalksTours) {
    const limitedWalksTours = newWalksTourList.slice(0, walksToursDefaultDisplay)
    newTourList.push(...limitedWalksTours)
  } else {
    newAddedIdx = newTourList.length + walksToursDefaultDisplay
    newTourList.push(...newWalksTourList)
  }
  newTourList.push(...unavailTours)
  return { tourList: newTourList, newAddedTourIdx: newAddedIdx }
}

export function makePhoneNumbersClickable (phoneStr) {
  const phoneRegex = /(\+\d+(?:[-.\s]?\d+)*)/g

  const phoneHtml = phoneStr.replace(phoneRegex, (match) => {
    // Remove any non-digit characters for the href
    const cleanNumber = match.replace(/[^+\d]/g, '')
    return `<a href="tel:${cleanNumber}" class="phone-number">${match}</a>`
  })
  return phoneHtml
}

/** END */

/** for ventrata */

export const convertToTimeStr = (dateTimeStr, format) => {
  // dateTimeStr could be: 2025-02-18T11:00:00-05:00 or 2025-02-18T11:00:00+02:00
  // New york time or Euro time
  const removeTimezoneStr = dateTimeStr.slice(0, 19)
  return moment(removeTimezoneStr).format(format)
}

export const getEndOfMonthStr = (date, format) => {
  const lastDay = moment(date).endOf('month').format(format)
  return lastDay
}

export const getStartOfMonthStr = (date, format) => {
  return moment(date).startOf('month').format(format)
}

export const removeLeadingZeros = (dateStr) => {
  const [year, month, day] = dateStr.split('-')
  return `${year}-${parseInt(month)}-${parseInt(day)}`
}

export const addLeadingZeros = (dateStr) => {
  if (!dateStr) {
    return
  }
  const [year, month, day] = dateStr.split('-')
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
}

/**
 * transform date to format 'Thursday, May 20th'
 * @param {String} date
 * @returns {String}
 */
export const transformDate = (date) => {
  const formattedDate = moment(date).format('dddd, MMM Do')
  return formattedDate
}

/**
 * Get the ordinal suffix for a number (st, nd, rd, th)
 * @param {number} number - The number to get the ordinal suffix for
 * @returns {string} The ordinal suffix ('st', 'nd', 'rd', or 'th')
 * @example
 * // returns "st"
 * getOrdinalSuffix(1)
 * // returns "nd"
 * getOrdinalSuffix(2)
 * // returns "rd"
 * getOrdinalSuffix(3)
 * // returns "th"
 * getOrdinalSuffix(4)
 */
export function getOrdinalSuffix (number) {
  const j = number % 10
  const k = number % 100

  if (j == 1 && k != 11) {
    return 'st'
  }
  if (j == 2 && k != 12) {
    return 'nd'
  }
  if (j == 3 && k != 13) {
    return 'rd'
  }
  return 'th'
}

/**
 * transform date to format 'July 24, 2024'
 * @param {String} date
 * @returns {String}
 */
export const transformDateMdy = (date) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })
}

/**
 * Transform date to format 'Thursday, May 20th 2024, 14:30 GMT'
 * @param {String} date - ISO date string to transform
 * @returns {String} Formatted date string in the format 'Weekday, Month Day(st|nd|rd|th) Year, HH:MM GMT'
 * @example
 * // returns "Thursday, May 20th 2024, 14:30 GMT"
 * transformDetailDate("2024-05-20T14:30:00.000Z")
 */
export const transformDetailDate = (date) => {
  if (!moment(date).isValid()) {
    return ''
  }
  const utcDateTimeStr = moment.utc(date).format('dddd, MMMM Do YYYY, HH:mm')
  return `${utcDateTimeStr} GMT`
}

/**
 * convert date to am/pm format
 * @param {String} date
 * @returns {String}
 */
export const convertToAmpm = (date) => {
  if (!date) {
    return ''
  }
  const match = date.match(/([+-])(\d{2}):?(\d{2})$/)
  if (!match) {
    throw new Error('No valid timezone offset found in the ISO string')
  }

  const sign = match[1] === '+' ? 1 : -1
  const offsetHours = parseInt(match[2], 10)
  const utcHours = new Date(date).getUTCHours()
  const hours = utcHours + (offsetHours * sign)
  const minutes = new Date(date).getUTCMinutes()
  const ampm = hours >= 12 ? 'PM' : 'AM'
  const formattedHours = hours % 12 || 12
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  return `${formattedHours}:${formattedMinutes} ${ampm}`
}

/**
 * Check if a country name is a member of the European Union (EU)
 * @param {string} countryName - The name of the country to check
 * @returns {boolean} True if the country is an EU member state, false otherwise
 * @example
 * // returns true
 * onCheckIsEuCountryName("France")
 * // returns false
 * onCheckIsEuCountryName("United States")
 */
export const onCheckIsEuCountryName = (countryName) => {
  const euCountryNames = ['Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden']
  return euCountryNames.includes(countryName)
}

export function isEUMember (countryCode) {
  const euCountryCodes = [
    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK',
    'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE',
    'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL',
    'PT', 'RO', 'SK', 'SI', 'ES', 'SE'
  ]

  return euCountryCodes.includes(countryCode.toUpperCase())
}

export function onCheckIsDayValid (year, month, day) {
  if (month < 1 || month > 12) { return false }

  const thisMonth = new Date(year, month - 1, 1)
  const nextMonth = new Date(year, month, 1)
  const daysInMonth = (nextMonth - thisMonth) / (1000 * 60 * 60 * 24)

  return day >= 1 && day <= daysInMonth
}

export const formatDate = (dateStr, targetFormat, originalFormat = '') => {
  // 'MM/DD/YYYY'
  return originalFormat
    ? moment(dateStr, originalFormat).format(targetFormat)
    : moment(dateStr).format(targetFormat)
}

function getSlugByString (slug) {
  if (!slug) {
    return ''
  }

  if (slug.lastIndexOf('/') === slug.length - 1) {
    slug = slug.slice(0, -1)
  }

  if (!slug) {
    return ''
  }

  return slug.split('/').pop()
}

export function getSlug (item) {
  if (!item) {
    return ''
  }

  if (typeof item === 'string') {
    return getSlugByString(item)
  }

  const slug = item?.slug ?? item?.url ?? item?.tourPageUrl ?? item?.tourPageURL ?? ''

  if (slug) {
    return getSlugByString(slug)
  }

  if (typeof item.tour === 'object') {
    return getSlug(item.tour)
  }

  return ''
}
