import { formatDate } from '@/helpers/main'
const cartKey = 'ventrata_cart'

export const OrderStatus = {
  Confirmed: 'CONFIRMED',
  Pending: 'ON_HOLD',
  Expired: 'EXPIRED'
}

const customOrder = ['adult', 'senior', 'student', 'child', 'infant']

export const supportedTickets = ['adult', 'child', 'infant', 'student', 'senior']

export const getAdultPricing = (availabilityItem) => {
  return availabilityItem.unitPricing.find(p => p.unitType.toLowerCase() === 'adult' && p.original > 0)
}

/**
 * Finds the tour session with the lowest price for adults
 * @param {Array} tourSessions - Array of tour session objects
 * @returns {Object} - The tour session object with the lowest adult price
 */
export function findLowestPriceAvailability(tourSessions) {
  if (!tourSessions || !Array.isArray(tourSessions) || tourSessions.length === 0) {
    return null;
  }

  // Filter out sessions without adult pricing
  const itemsWithAdultPricing = tourSessions.filter(session => getAdultPricing(session));
  
  if (itemsWithAdultPricing.length === 0) {
    return null;
  }
  
  // Sort sessions by adult price (lowest first)
  const sortedSessions = [...itemsWithAdultPricing].sort((a, b) => {
    const priceA = getAdultPricing(a).retail;
    const priceB = getAdultPricing(b).retail;
    return priceA - priceB;
  });
  
  // Return the first (lowest price) session
  return sortedSessions[0];
}

export const getAvailabilityPayload = (product, startDateTime, endDateTime, currency) => {
  const defaultOption = product.options[0]
  const optionId = defaultOption.id
  // const adultUnit = defaultOption.units.find(u => u.title.toLowerCase() === 'adult')
  // const unitId = adultUnit.id
  return {
    productId: product.id,
    optionId,
    localDateStart: startDateTime, // '2025-01-03'
    localDateEnd: endDateTime, // '2025-01-03'
    // units: [
    //   {
    //     id: unitId,
    //     quantity: 1,
    //     extras: []
    //   }
    // ],
    currency: currency ?? product.defaultCurrency
  }
}

/**
 * Splits a full name into first name and last name
 * @param {string} fullName - The full name to split
 * @returns {Object} Object containing firstName and lastName
 */
export function splitFullName (fullName) {
  // Return empty if no name provided
  if (!fullName) {
    return { firstName: '', lastName: '' }
  }

  // Trim and normalize spaces
  fullName = fullName.trim().replace(/\s+/g, ' ')

  // Common name suffixes to handle
  const suffixes = /\b(jr|sr|phd|md|ii|iii|iv)\b/i

  // Remove suffix if present and store it
  let suffix = ''
  fullName = fullName.replace(suffixes, (match) => {
    suffix = match
    return ''
  }).trim()

  // Split the remaining name parts
  const nameParts = fullName.split(' ')

  // Handle single word name
  if (nameParts.length === 1) {
    return {
      firstName: nameParts[0],
      lastName: ''
    }
  }

  // Get first name (first word)
  const firstName = nameParts[0]

  // Get last name (everything else)
  const lastName = nameParts.slice(1).join(' ') + (suffix ? ' ' + suffix : '')

  return {
    firstName,
    lastName
  }
}

export function validateExpiry (expiry) {
  // Check basic format using regex (MM/YY)
  if (!/^\d{2}\/\d{2}$/.test(expiry)) {
    return false
  }

  const [month, year] = expiry.split('/').map(num => parseInt(num, 10))
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear() % 100 // Get last 2 digits
  const currentMonth = currentDate.getMonth() + 1 // getMonth() returns 0-11

  // Validate month is between 1 and 12
  if (month < 1 || month > 12) {
    return false
  }

  // Check if card is not expired
  if (year < currentYear || (year === currentYear && month < currentMonth)) {
    return false
  }

  return true
}

export function parseExpiryDate (expiry) {
  // First validate the format
  if (!/^\d{2}\/\d{2}$/.test(expiry)) {
    throw new Error('Invalid expiry format. Use MM/YY')
  }

  const [month, shortYear] = expiry.split('/')

  // Get current century (2000s)
  const century = Math.floor(new Date().getFullYear() / 100) * 100

  // Convert to full year (assuming 20XX)
  const fullYear = century + parseInt(shortYear, 10)

  return {
    month, // keeps leading zero
    year: fullYear.toString()
  }
}

export const computeMaxTickets = ({ selectedUnits, totalTicketsNumber, unitsWithPrice }) => {
  // Initialize result object
  const maxTicketsMap = {}

  // Get total selected tickets
  const totalSelectedTickets = selectedUnits.reduce((sum, unit) => sum + unit.pax, 0)

  // Calculate remaining tickets
  const remainingTickets = Math.max(totalTicketsNumber - totalSelectedTickets, 0)

  // Create a map of selected units for easier lookup
  const selectedUnitsMap = selectedUnits.reduce((map, unit) => {
    map[unit.unitId] = unit.pax
    return map
  }, {})

  unitsWithPrice.forEach((unit) => {
    const currentPax = selectedUnitsMap[unit.unitId] || 0

    if (currentPax > 0) {
      // For units that have pax > 0, max is current pax + remaining tickets which cannot exceed max ticket number
      maxTicketsMap[unit.unitId] = Math.min(currentPax + remainingTickets, totalTicketsNumber)
    } else {
      // For units with no selection, max is remaining tickets
      maxTicketsMap[unit.unitId] = remainingTickets
    }
  })

  return maxTicketsMap
}

export function sortByCustomOrder (items) {
  return items.sort((a, b) => {
    const indexA = customOrder.indexOf(a.title?.toLowerCase())
    const indexB = customOrder.indexOf(b.title?.toLowerCase())
    return indexA - indexB
  })
}

export function accompaniedTicketToAdd (unitInfo, validUnits, currentSelections) {
  if (!unitInfo) {
    return null
  }
  const accompaniedBy = unitInfo?.restrictions?.accompaniedBy
  if (!accompaniedBy) {
    return null
  }
  const validAccompaniedBy = accompaniedBy.filter((item) => {
    // Check if the item exists in unitsWithPrice
    return validUnits.some(unit => unit.unitId === item)
  })

  // console.log('validAccompaniedBy :>> ', validAccompaniedBy)
  if (validAccompaniedBy?.length > 0) {
    const accompaniedTickets = currentSelections.filter(selected => validAccompaniedBy.includes(selected.unitId) && selected.pax > 0)
    if (accompaniedTickets?.length > 0) {
      return null
    }
    return validAccompaniedBy[0]
  }
  return null
}

export function validateTicketTypes (currentSelections, units) {
  if (!currentSelections) {
    return true
  }
  const validSelections = currentSelections.filter(cs => cs.pax > 0)
  if (validSelections.length <= 0) {
    return true
  }
  for (const currentSelection of validSelections) {
    const unitId = currentSelection.unitId
    const unitInfo = units.find(u => u.id === unitId)
    if (!unitInfo) {
      continue
    }
    const accompaniedBy = unitInfo?.restrictions?.accompaniedBy
    if (!accompaniedBy || accompaniedBy.length <= 0) {
      continue
    }
    // console.log('accompaniedBy :>> ', accompaniedBy)
    const accompaniedTickets = currentSelections.filter(selected => accompaniedBy.includes(selected.unitId) && selected.pax > 0)
    if (!accompaniedTickets || accompaniedTickets.length <= 0) {
      // err - need to add accompanied tickets
      return false
    }
  }
  return true
}

export function getGuestSummary (booking) {
  const unitItems = booking && booking.unitItems ? booking.unitItems : []

  const items = [
    { label: 'adult', unitType: 'ADULT', count: 0 },
    { label: 'senior', unitType: 'SENIOR', count: 0 },
    { label: 'infant', unitType: 'INFANT', count: 0 },
    { label: 'child', unitType: 'CHILD', count: 0 }
  ].map((i) => {
    return { ...i, count: unitItems.filter(unit => unit.unitType === i.unitType).length }
  })

  const guests = unitItems.length

  const text = items
    .filter(i => i.count > 0)
    .map(i => `${i.count} ${i.label}`)
    .join(', ')

  return `${guests} ${guests <= 1 ? 'Guest' : 'Guests'} (${text})`
}

export function getCancellationReminder (booking) {
  const product = booking.product
  const option = (product?.options || [])[0]
  const cancellationCutoffAmount = option?.cancellationCutoffAmount
  if (cancellationCutoffAmount) {
    return `Free Cancellation up to ${option.cancellationCutoff} before tour date`
  } else {
    return 'Free Cancellation before tour date'
  }
}

function getBookingNumFromLocalStorage () {
  const cartStr = localStorage.getItem(cartKey)
  if (!cartStr) {
    return 0
  }

  const localStorageCart = JSON.parse(cartStr)
  return localStorageCart.bookings?.length ?? 0
}

export function checkOrderChanged (order) {
  // check if there's new booking
  const bookingNum = getBookingNumFromLocalStorage()
  if (bookingNum !== order.bookings?.length) {
    return true
  }
  return false
}

export function checkBookingsChanged (bookings) {
  // check if there's new booking
  const bookingNum = getBookingNumFromLocalStorage()
  if (bookingNum !== bookings?.length) {
    return true
  }
  return false
}


export function getProducUtag (product) {
  let productIdArr = [];
  let productIdName = [];
  let productVariant = [];
  let productVariantId = [];
  let productPrice = [];
  let productListPrice = [];
  const ventrataProduct = product.ventrataProduct
  const units = ventrataProduct?.options?.[0].units?.filter( unit => supportedTickets.includes(unit.title?.toLowerCase()))
  if (units) {
    units.forEach((unit) => {
      // console.log('getProducUtag unit => ', unit)
      productIdArr.push(product.bookingTypeId)
      productIdName.push(product.shortTitle)
      productVariant.push(unit.internalName)
      productVariantId.push(unit.id)
      const price = unit.pricingFrom.find(p => p.currency === 'USD')
      productPrice.push(price.original / 100)
      productListPrice.push(price.original / 100)
    })
  }
  return {
    product_id: productIdArr,
    product_name: productIdName,
    property_id: product.propertyId,
    product_variant_id: productVariantId,
    product_variant: productVariant,
    product_price: productPrice,
    product_list_price: productListPrice,
    currency: 'USD',
  }
}

export function getBookingUtagFormatData (booking) {
  const pricing = booking.pricing
  const originalPrice = pricing.original / 100
  const retailPrice = pricing.retail / 100
  const totalPriceStr = originalPrice.toFixed(2)
  const orderDiscount = originalPrice - retailPrice
  const bookingDate = booking.availabilityId.slice(0, 10)
  const selectedDate = formatDate(bookingDate, 'MM/DD/YYYY')
  // const filteredTickets = units.filter( unit => supportedTickets.includes(unit.title?.toLowerCase()))

  const unitItems = booking.unitItems
  const tourInfo = booking.metadata
  const unitsInfo = transformBookingData(unitItems, tourInfo)

  return {
    property_id: tourInfo.propertyId,
    // order_id: getOrderId(booking),
    booking_id: booking.id,
    booking_total: retailPrice.toFixed(2),
    booking_subtotal: totalPriceStr,
    booking_discount: orderDiscount.toFixed(2),
    currency: pricing?.currency, // pricing?
    booking_tax: '0.00',
    date_selection: selectedDate,
    ...unitsInfo,
  }
}

export function getOrderUtagFormatData (order) {
  let propertyId = [];
  let ventrataPropertyId = [];
  let productVariant = [];
  let productPrice = [];
  let productListPrice = [];
  let productQuantity = [];
  let productIdArr = [];
  let productNameArr = [];
  let productVariantIdArr = [];
  let sfOrderIdArr = [];

  order.bookings.forEach(booking => {
    const unitItems = booking.unitItems
    const tourInfo = booking.metadata
    const vPropId = getPropertyId(booking)
    ventrataPropertyId.push(vPropId)
    propertyId.push(tourInfo.propertyId)
    sfOrderIdArr.push(getOrderId(booking))
    const unitsInfo = transformBookingData(unitItems, tourInfo)
    productVariant = productVariant.concat(unitsInfo.product_variant)
    productVariantIdArr = productVariantIdArr.concat(unitsInfo.product_variant_id)
    productPrice = productPrice.concat(unitsInfo.product_price)
    productListPrice = productListPrice.concat(unitsInfo.product_list_price)
    productQuantity = productQuantity.concat(unitsInfo.product_quantity)
    productIdArr = productIdArr.concat(unitsInfo.product_id)
    productNameArr = productNameArr.concat(unitsInfo.product_name)
  });

  return {
    product_id: productIdArr,
    product_name: productNameArr,
    property_id: propertyId.join(','),
    // order_id: sfOrderIdArr,
    ventrata_property_id: ventrataPropertyId,
    product_variant_id: productVariantIdArr,
    product_variant: productVariant,
    product_price: productPrice,
    product_list_price: productListPrice,
    product_quantity: productQuantity
  }
}

export function getOrderUtagFormatDataByBooking (booking) {
  const unitItems = booking.unitItems
  const tourInfo = booking.metadata

  let productVariant = [];
  let productPrice = [];
  let productListPrice = [];
  let productQuantity = [];
  let productIdArr = [];
  let productNameArr = [];
  let productVariantIdArr = [];

  const vPropId = getPropertyId(booking)
  const unitsInfo = transformBookingData(unitItems, tourInfo)
  productVariant = productVariant.concat(unitsInfo.product_variant)
  productVariantIdArr = productVariantIdArr.concat(unitsInfo.product_variant_id)
  productPrice = productPrice.concat(unitsInfo.product_price)
  productListPrice = productListPrice.concat(unitsInfo.product_list_price)
  productQuantity = productQuantity.concat(unitsInfo.product_quantity)
  productIdArr = productIdArr.concat(unitsInfo.product_id)
  productNameArr = productNameArr.concat(unitsInfo.product_name)

  return {
    product_id: productIdArr,
    product_name: productNameArr,
    property_id: tourInfo.propertyId,
    order_id: getOrderId(booking),
    ventrata_property_id: vPropId,
    product_variant_id: productVariantIdArr,
    product_variant: productVariant,
    product_price: productPrice,
    product_list_price: productListPrice,
    product_quantity: productQuantity,
    booking_reference: booking.supplierReference
  }
}

function transformBookingData(unitItems, tourInfo) {
  // Initialize the output format
  const output = {
      product_id: [],
      product_name: [],
      product_variant_id: [],
      product_variant: [],
      product_price: [],
      product_list_price: [],
      product_quantity: []
  }

  // Create a map to count quantities of each unit type
  const quantityMap = {}
  
  // First pass: count quantities
  unitItems.forEach(item => {
      const unitType = item.unitType
      quantityMap[unitType] = (quantityMap[unitType] || 0) + 1
  })

  const productId = tourInfo.bookingTypeId
  const productName = tourInfo.shortTitle
  // Create a Set to track processed unit types
  const processedTypes = new Set()

  // Second pass: format the data
  unitItems.forEach(item => {
      const unitType = item.unitType
      
      // Only process each unit type once
      if (!processedTypes.has(unitType)) {
          processedTypes.add(unitType)
          
          // Get the variant name from the unit title
          const variantName = item.unit.title
          const unitId = item.unit.id
          
          // Get the price in AUD (assuming first pricing entry is AUD)
          // Convert from cents to dollars and format to 2 decimal places
          const price = (item.unit.pricing[0].retail / 100).toFixed(2)
          
          // Add to output arrays
          output.product_id.push(productId)
          output.product_name.push(productName)
          output.product_variant.push(variantName)
          output.product_variant_id.push(unitId)
          output.product_price.push(price)
          output.product_list_price.push(price)
          output.product_quantity.push(quantityMap[unitType])
      }
  })

  return output
}

function getOrderId(booking) {
  const propertyId = getPropertyId(booking);
  return `${propertyId}B${booking.id}`;
}

function getPropertyId (booking) {
  const destination = booking.product.destination;
  const name = (destination?.name || 'unknown').toLowerCase();
  const cityName = name.replace(' ', '_');
  return `ventrata-${cityName}`;
}

export function getUtagContact (contact) {
  // console.log('getUtagContact contact :>> ', contact)
  return {
    billing_cust_email: contact?.emailAddress ?? '',
    billing_cust_first_name: contact?.firstName ?? '',
    billing_cust_last_name: contact?.lastName ?? '',
    billing_cust_country: contact?.country ?? '',
    billing_cust_city: contact?.city ?? '',
    billing_cust_state: contact?.state ?? '',
    billing_cust_phone: contact?.phoneNumber ?? '',
    billing_cust_address: contact?.streetAddress ?? '',
    billing_cust_postal_code: contact?.postalCode ?? '',
  }
}

/**
 * Replaces availabilities in the original array with new availabilities that match by ID
 * 
 * @param {Array} originalAvailabilities - Array of original availability objects
 * @param {Array} newAvailabilities - Array of new availability objects to replace matching ones
 * @return {Array} Updated array of availabilities
 */
export function replaceAvailabilitiesById(originalAvailabilities, newAvailabilities) {
  if (!Array.isArray(originalAvailabilities) || !Array.isArray(newAvailabilities)) {
    throw new Error('Both arguments must be arrays');
  }

  // Create a map of new availabilities indexed by ID for quick lookup
  const newAvailabilitiesMap = newAvailabilities.reduce((map, availability) => {
    if (availability && availability.id) {
      map[availability.id] = availability;
    }
    return map;
  }, {});

  // Create a new array with replaced availabilities
  const updatedAvailabilities = originalAvailabilities.map(originalAvailability => {
    // If there's a matching ID in the new availabilities, return that instead
    if (originalAvailability.id && newAvailabilitiesMap[originalAvailability.id]) {
      return newAvailabilitiesMap[originalAvailability.id];
    }
    // Otherwise return the original availability
    return originalAvailability;
  });

  return updatedAvailabilities;
}